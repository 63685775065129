import {
  receptionPlaces,
  shippingTypes,
} from '../../../../../utils/calculatorInfo';

export type CalculatorState = {
  weight: string;
  height: string;
  width: string;
  length: string;
  boxes: string;
  receptionPlace: string;
  shippingType: string;
  shippingCost: string;
  shippingCostError: boolean;
  showResult: boolean;
};

export type CalculatorAction =
  | { type: 'SET_WEIGHT'; payload: string }
  | { type: 'SET_HEIGHT'; payload: string }
  | { type: 'SET_WIDTH'; payload: string }
  | { type: 'SET_LENGTH'; payload: string }
  | { type: 'SET_RECEPTION_PLACE'; payload: string }
  | { type: 'SET_SHIPPING_TYPE'; payload: string }
  | { type: 'SET_SHIPPING_COST'; payload: string }
  | { type: 'SET_SHIPPING_COST_ERROR'; payload: boolean }
  | { type: 'SET_BOXES'; payload: string }
  | { type: 'SET_SHOW_RESULT'; payload: boolean };

export const initialState: CalculatorState = {
  weight: '',
  height: '',
  width: '',
  length: '',
  receptionPlace: receptionPlaces[0].value,
  shippingType: shippingTypes[1].value,
  shippingCost: '',
  shippingCostError: false,
  showResult: false,
  boxes: '1',
};

export function calculatorReducer(
  state: CalculatorState,
  action: CalculatorAction
): CalculatorState {
  switch (action.type) {
    case 'SET_WEIGHT':
      return { ...state, weight: action.payload };
    case 'SET_HEIGHT':
      return { ...state, height: action.payload };
    case 'SET_WIDTH':
      return { ...state, width: action.payload };
    case 'SET_LENGTH':
      return { ...state, length: action.payload };
    case 'SET_RECEPTION_PLACE':
      return { ...state, receptionPlace: action.payload };
    case 'SET_SHIPPING_TYPE':
      return { ...state, shippingType: action.payload };
    case 'SET_SHIPPING_COST':
      return { ...state, shippingCost: action.payload };
    case 'SET_SHIPPING_COST_ERROR':
      return { ...state, shippingCostError: action.payload };
    case 'SET_SHOW_RESULT':
      return { ...state, showResult: action.payload };
    case 'SET_BOXES':
      return { ...state, boxes: action.payload };
    default:
      return state;
  }
}
