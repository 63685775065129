import { gql } from '@apollo/client';
import {
  UserFragment,
  AdvertisementFragment,
  OrderFragment,
  ShipmentFragment,
  FreightFragment,
  PaymentMethodFragment,
  PaymentFragment,
  PromotionFragment,
} from './fragments';

export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;

// Advertisements
export const GET_ADVERTISEMENT = gql`
  query GET_ADVERTISEMENT($filter: FilterFindOneAdvertisementInput) {
    advertisement(filter: $filter) {
      ...AdvertisementFragment
    }
  }
  ${AdvertisementFragment}
`;

export const GET_ADVERTISEMENTS = gql`
  query GET_ADVERTISEMENTS(
    $filter: FilterFindManyAdvertisementInput
    $sort: SortFindManyAdvertisementInput
  ) {
    advertisements(filter: $filter, sort: $sort) {
      ...AdvertisementFragment
    }
  }
  ${AdvertisementFragment}
`;

// Orders
export const GET_ORDER = gql`
  query GET_ORDER($filter: FilterFindOneOrderInput) {
    order(filter: $filter) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

// limit 2000
export const GET_ORDERS = gql`
  query GET_ORDERS(
    $filter: FilterFindManyOrderInput
    $sort: SortFindManyOrderInput
  ) {
    orders(filter: $filter, sort: $sort, limit: 2000) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

export const GET_SHIPMENT = gql`
  query GET_SHIPMENT($filter: FilterFindOneShipmentInput) {
    shipment(filter: $filter) {
      ...ShipmentFragment
    }
  }
  ${ShipmentFragment}
`;

export const GET_SHIPMENTS = gql`
  query GET_SHIPMENTS(
    $filter: FilterFindManyShipmentInput # $sort: SortFindManyOrderInput
  ) {
    shipments(filter: $filter) {
      ...ShipmentFragment
    }
  }
  ${ShipmentFragment}
`;

export const GET_FREIGHT = gql`
  query GET_FREIGHT($filter: FilterFindOneFreightInput) {
    freight(filter: $filter) {
      ...FreightFragment
    }
  }
  ${FreightFragment}
`;

export const GET_FREIGHTS = gql`
  query GET_FREIGHTS(
    $filter: FilterFindManyFreightInput
    $sort: SortFindManyFreightInput
  ) {
    freights(filter: $filter, sort: $sort) {
      ...FreightFragment
    }
  }
  ${FreightFragment}
`;

export const GET_PAYMENT_METHODS = gql`
  query GET_PAYMENT_METHODS(
    $filter: FilterFindManyPaymentMethodInput
    $sort: SortFindManyPaymentMethodInput
  ) {
    paymentMethods(filter: $filter, sort: $sort) {
      ...PaymentMethodFragment
    }
  }
  ${PaymentMethodFragment}
`;

export const FIND_FREIGHT_BY_CODE = gql`
  query FIND_FREIGHT_BY_CODE($data: FindFreightByCodeInput) {
    findFreightByCode(data: $data) {
      ...FreightFragment
    }
  }
  ${FreightFragment}
`;

export const FIND_ORDER_BY_CODE = gql`
  query FIND_ORDER_BY_CODE($data: FindOrderByCodeInput) {
    findOrderByCode(data: $data) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

// payments query
export const GET_PAYMENT = gql`
  query GET_PAYMENT($filter: FilterFindOnePaymentInput) {
    payment(filter: $filter) {
      _id
      reference
      paymentDate
      method {
        _id
        name
      }
      freight {
        _id
        code
      }
      amount
      image
      status
      details {
        name
        value
      }
    }
  }
`;

export const GET_RECEPTION_REGIONS = gql`
  query GET_RECEPTION_REGIONS(
    $filter: FilterFindManyReceptionRegionInput
    $sort: SortFindManyReceptionRegionInput
  ) {
    receptionRegions(filter: $filter, sort: $sort) {
      receptionPlaces {
        name
        value
        _id
        receptionRegion
      }
      rates {
        minCbm
        maxCbm
        rate
      }
      key
      _id
      name
    }
  }
`;

export const GET_PAYMENTS = gql`
  query GET_PAYMENTS(
    $filter: FilterFindManyPaymentInput
    $sort: SortFindManyPaymentInput
  ) {
    payments(filter: $filter, sort: $sort) {
      _id
      createdAt
      reference
      paymentDate
      method {
        _id
        name
      }
      freight {
        _id
        code
      }
      amount
      image
      status
      details {
        name
        value
      }
    }
  }
`;

export const GET_RATE = gql`
  query GET_RATE($filter: FilterFindOneRateInput) {
    rate(filter: $filter) {
      _id
      value
    }
  }
`;

export const GET_PAYMENTS_FOR_FREIGHT = gql`
  query GET_PAYMENTS_FOR_FREIGHT($freightId: String!) {
    getPaymentsForFreight(freightId: $freightId) {
      ...PaymentFragment
    }
  }
  ${PaymentFragment}
`;

export const GET_PROMOTION = gql`
  query GET_PROMOTION(
    $filter: FilterFindOnePromotionInput
    $skip: Int
    $sort: SortFindOnePromotionInput
  ) {
    promotion(filter: $filter, skip: $skip, sort: $sort) {
      ...PromotionFragment
    }
  }
  ${PromotionFragment}
`;
