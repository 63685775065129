/* eslint-disable no-plusplus */
import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useQuery } from '@apollo/client';
import { useNotify } from '../../../../../hooks';
import {
  receptionPlaces,
  shippingTypes,
  getShippingRates,
} from '../../../../../utils/calculatorInfo';
import Button from '../../../../common/button/Button';
import { Input } from '../../../../common/input/Input';
import { Select } from '../../../../common/select/Select';
import {
  calculatorReducer,
  CalculatorState,
  initialState,
} from './calculatorReducer';
import { GET_RECEPTION_REGIONS } from '../../../../../graphql/queries';

function Calculator() {
  const notify = useNotify();
  const { data, loading } = useQuery(GET_RECEPTION_REGIONS, {});
  const [state, dispatch] = React.useReducer(calculatorReducer, initialState);
  const {
    weight,
    height,
    width,
    length,
    receptionPlace,
    shippingType,
    shippingCost,
    shippingCostError,
    showResult,
    boxes,
  } = state;
  const [kgCbm, setKgCbm] = React.useState(0);
  const [cmCbm, setCmCbm] = React.useState(0);
  const [cbmState, setCbmState] = React.useState(0);
  const [message, setMessage] = React.useState('');

  function getRateByCbm(cbm, rates) {
    for (let i = 0; i < rates.length; i++) {
      const { minCbm, maxCbm, rate } = rates[i];
      if (cbm >= minCbm && cbm <= maxCbm) {
        return rate;
      }
    }
    return null; // if cbm is outside the defined range, return null or throw an error
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validate input
    if (!weight || !height || !width || !length) {
      dispatch({ type: 'SET_SHIPPING_COST_ERROR', payload: false });
      return;
    }

    // in reception place, find the receptioRegion by the value

    const _region = receptionPlaces.find(
      (place) => place.value === receptionPlace
    );

    // Calculate shipping cost
    try {
      const cost = calculateShippingCost(
        parseFloat(weight),
        parseFloat(height),
        parseFloat(width),
        parseFloat(length),
        _region.receptioRegion,
        shippingType
      );

      dispatch({ type: 'SET_SHIPPING_COST', payload: cost });
      dispatch({ type: 'SET_SHOW_RESULT', payload: true });
    } catch (error) {
      dispatch({ type: 'SET_SHIPPING_COST_ERROR', payload: true });
    }
  };

  function calculateShippingCost(
    _weight: number,
    _height: number,
    _width: number,
    _length: number,
    _receptionPlace: string,
    _shippingType: string
  ) {
    const volumetricSeaWeight = (_height * _width * _length) / 1000000;
    const seaWeight = _weight / 420;
    let seaCbm = Math.max(volumetricSeaWeight, seaWeight);

    // Ajustar el CBM mínimo para envíos marítimos
    if (_shippingType === 'sea' && seaCbm < 0.05) {
      seaCbm = 0.05;
    }
    setCmCbm(seaCbm);
    const volumetricAirWeight = (_height * _width * _length) / 5000;
    const airCbm = Math.max(volumetricAirWeight, _weight);
    setKgCbm(Math.max(volumetricAirWeight, _weight));
    const cbm = Math.max(_shippingType === 'air' ? airCbm : seaCbm);

    // Get the shipping rates based on the reception place and shipping type
    let totalCost = 0;
    if (shippingType === 'air') {
      if (airCbm < 20) {
        console.log('error');
        notify('Su peso debe ser mayor a 25 kg', 'error');
        dispatch({ type: 'SET_SHIPPING_COST_ERROR', payload: true });

        setMessage(
          'Debido a que el peso es menor a 25 kg, la cotización se hace directamente a través de WhatsApp.'
        );

        return;
      }
      dispatch({ type: 'SET_SHIPPING_COST_ERROR', payload: false });
      setMessage(
        `El ${
          shippingType === 'air' ? 'PESO' : 'CBM'
        } se calcula con la comparación de ${volumetricAirWeight} y el peso ${_weight}. El ${
          shippingType === 'air' ? 'PESO' : 'CBM'
        } es ${cbm}`
      );
      totalCost = 25 * cbm * Number(boxes);
      return totalCost.toFixed(2);
    }
    const shippingRates = getShippingRates(
      data?.receptionRegions,
      _receptionPlace,
      shippingType
    );
    // Find the rate that applies to the billable weight
    const actualRate = getRateByCbm(cbm, shippingRates);
    setMessage(
      `El ${
        shippingType === 'air' ? 'PESO' : 'CBM'
      } se calcula con la comparación de ${volumetricSeaWeight?.toFixed(
        4
      )} y el peso ${seaWeight?.toFixed(4)}. El ${
        shippingType === 'air' ? 'PESO' : 'CBM'
      } es ${cbm?.toFixed(4)} y la tarifa es $${actualRate}.`
    );

    // Calculate the total shipping cost
    totalCost = actualRate * cbm * Number(boxes);

    return totalCost.toFixed(2);
  }

  const validateFields = () => {
    if (!weight || !height || !width || !length) {
      dispatch({ type: 'SET_SHIPPING_COST_ERROR', payload: false });
      return true;
    }
  };

  React.useEffect(() => {
    if (!validateFields()) {
      // Calculate shipping cost

      const _region = receptionPlaces.find(
        (place) => place.value === receptionPlace
      );

      try {
        const cost = calculateShippingCost(
          parseFloat(weight),
          parseFloat(height),
          parseFloat(width),
          parseFloat(length),
          _region.receptioRegion,
          shippingType
        );

        dispatch({ type: 'SET_SHIPPING_COST', payload: cost });
        dispatch({ type: 'SET_SHOW_RESULT', payload: true });
      } catch (error) {
        dispatch({ type: 'SET_SHIPPING_COST_ERROR', payload: true });
      }
    }
  }, [
    state?.weight,
    state?.height,
    state?.width,
    state?.length,
    state?.shippingType,
    state?.receptionPlace,
    boxes,
  ]);

  return (
    <div>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <TailSpin
            height={80}
            width={80}
            color="#3375B4"
            ariaLabel="loading"
          />
        </div>
      ) : (
        <form
          className="bg-neutral-100 w-full h-fit p-4 rounded-md flex flex-col gap-4"
          onSubmit={onSubmit}
        >
          {/* Section for shipping information */}
          <div className="flex flex-col gap-4 items-start">
            <h1 className="text-font-black text-lg font-semibold">
              Información del envío
            </h1>
            <div className="flex flex-col items-center gap-2 w-full md:flex-row">
              {/* Select element for reception place */}
              <Select
                label="Lugar de recepción"
                labelClassName="w-full md:w-1/4 text-sm font-medium "
                onChange={(e) => {
                  dispatch({
                    type: 'SET_RECEPTION_PLACE',
                    payload: e.target.value,
                  });
                }}
                value={state?.receptionPlace}
              >
                {receptionPlaces?.map((place) => (
                  <option key={place.value} value={place.value}>
                    {place.name}
                  </option>
                ))}
              </Select>
              {/* Select element for shipping type */}
              <Select
                label="Tipo de envío"
                labelClassName="w-full md:w-1/4 text-sm font-medium "
                onChange={(e) => {
                  dispatch({
                    type: 'SET_SHIPPING_TYPE',
                    payload: e.target.value,
                  });
                }}
                value={shippingType}
              >
                {shippingTypes?.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.name}
                  </option>
                ))}
              </Select>
            </div>
          </div>
          <hr className="w-full border-neutral-300 " />
          {/* Section for package information */}
          <div className="flex flex-col gap-2 items-start">
            <h1 className="text-font-black text-lg font-semibold">
              Información del paquete
            </h1>
            <div className="w-full flex flex-col flex-wrap gap-2 items-center md:flex-row">
              {/* Input element for width */}
              <Input
                label="Ancho (cm)"
                labelClassName="w-full md:w-1/4 text-sm font-medium "
                className="py-0"
                onChange={(e) => {
                  dispatch({ type: 'SET_WIDTH', payload: e.target.value });
                }}
                value={width}
                type="number"
              />
              {/* Input element for length */}
              <Input
                label="Largo (cm)"
                labelClassName="w-full md:w-1/4 text-sm font-medium "
                onChange={(e) => {
                  dispatch({ type: 'SET_LENGTH', payload: e.target.value });
                }}
                value={length}
                className="py-0"
                type="number"
              />
              {/* Input element for height */}
              <Input
                label="Alto (cm)"
                labelClassName="w-full md:w-1/4 text-sm font-medium "
                onChange={(e) => {
                  dispatch({ type: 'SET_HEIGHT', payload: e.target.value });
                }}
                value={height}
                className="py-0"
                type="number"
              />
              {/* Input element for weight */}
              <Input
                label="Peso (Kg)"
                labelClassName="w-full md:w-1/4 text-sm font-medium "
                onChange={(e) => {
                  dispatch({ type: 'SET_WEIGHT', payload: e.target.value });
                }}
                value={weight}
                className="py-0  "
                type="number"
              />
              <Input
                label="N° de Cajas"
                labelClassName="w-full md:w-1/4 text-sm font-medium "
                onChange={(e) => {
                  dispatch({ type: 'SET_BOXES', payload: e.target.value });
                }}
                value={boxes}
                className="py-0  "
                type="number"
              />
            </div>
          </div>
          <hr
            className={` w-full border-neutral-300 ${
              showResult ? '' : 'hidden'
            } `}
          />
          {/* Button to calculate the shipping cost */}
          {/* <Button className="ml-1">Calcular</Button> */}
          {/* result */}
          <div
            className={`flex flex-col gap-2 items-start ${
              showResult ? '' : 'hidden'
            }`}
          >
            {/* <h1 className="text-font-black font-">Resultado</h1> */}
            <div className="flex flex-col gap-2 items-center md:flex-row">
              <p
                className={`text-font-black font-bold text-2xl ${
                  shippingCostError ? 'text-lg' : ''
                }`}
              >
                {shippingCostError ? `${message}` : shippingCost}
              </p>
              <p className="text-font-black font-bold text-2xl">
                {shippingCostError ? '' : '$'}
              </p>
            </div>
            <p className="text-font-black font-bold hidden">{message}</p>
          </div>
        </form>
      )}
    </div>
  );
}

export default Calculator;
